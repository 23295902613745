<div id="support">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Wsparcie psychologiczne</div>
        <p>
          Jeśli doświadczasz stresującej sytuacji życiowej, czujesz przeciążenie z powodu zbyt dużej ilości spraw,
          jesteś przemęczony, masz trudność w przystosowaniu się do zmian np. narodziny dziecka czy wyjazdu
          zagranicę.
        </p>
        <app-online></app-online>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>
          Podczas wsparcia psychologicznego będziesz mieć możliwość wyrażenia swoich uczuć, rozładowania napięć
          emocjonalnych. Podczas spotkań psycholog będzie Ci towarzyszył w trakcie szukania rozwiązań, pomoże wzmocnić
          poczucie własnej wartości i Twoje Zasoby. Wsparcie psychologiczne ma przeważnie charakter krótkoterminowy.
          Czasami częścią wsparcia psychologicznego jest też psychoedukacja – np. edukacja o stresie i radzeniu sobie z
          nim. Informacje są udzielane zgodnie z obecnym stanem wiedzy psychologicznej. Psychoedukacja jest często
          bardzo pomocna dla osób cierpiących na zaburzenia psychiczne i ich bliskich wyjaśniając np. objawy depresji.
          Może to pomóc zrozumieć konkretne zachowania.
        </p>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>

