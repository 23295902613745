<div id="article-mini-card" class="container">
  <div class="row">
    <div class="col-md-5">
      <a [routerLink]="'/article/'+article.id" mdbWavesEffect>
        <div class="view overlay hoverable">
          <img [src]="article.imgUrl" [alt]="article.title" class="img-thumbnail" style="width: 100%">
        </div>
      </a>
    </div>
    <div class="col-md-7 title">
      <a [routerLink]="'/article/'+article.id"> {{article.title}}</a>
    </div>
  </div>
</div>


