<div id="individual">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Psychoterapia indywidualna</div>
        <p>Psychoterapia jest niezwykłym spotkaniem między osobą, która potrzebuje pomocy a terapeutą, podczas którego
          nawiązuje się relacja terapeutyczna. Dzięki czemu możesz poczuć się bezpiecznie.</p>
        <app-online></app-online>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>Ustalimy wspólnie cele, które chcesz osiągnąć – które mogą znacząco wpłynąć na poprawę Twojego funkcjonowania i
          samopoczucia. Cele mogą w trakcie terapii ewoluować, zmieniać się – jednak nic nie dzieje się wbrew Tobie, to Ty
          decydujesz, co zmienić. Ważnym elementem psychoterapii jest zdobycie większej samoświadomości – zrozumienie
          czemu podejmujesz takie, a nie inne decyzje, skąd biorą się dane zachowania – nad którymi się zastanawiasz,
          jakie emocje Tobą kierują i dlaczego. Refleksja, wgląd w siebie powoduje, że można się zatrzymać i coś następnym
          razem zrobić inaczej. Będę Ci towarzyszyć w tej drodze – ku własnemu rozwojowi. Czasami oznacza to powrót do
          trudnych przeżyć i niekomfortowych emocji, ale może to też sprawić, że dostrzeżesz w nich coś nowego – co w
          efekcie może przynieść Ci ulgę.
        </p>
        <p>Psychoterapia daje możliwość rozwinięcia i przećwiczenia w bezpiecznych warunkach nowych sposobów radzenia sobie
          z trudnościami, daje przestrzeń do dzielenia się z napotkanymi w danym procesie problemami. Jednocześnie
          sprawiając, że nowo zdobyte umiejętności i szersza perspektywa przenosi się na codzienne funkcjonowanie i wpływa
          na większe poczucie własnej pewności i sprawczości.</p>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>Psychoterapia jest skierowana dla osób:</p>
        <ul>
          <li>chcą poprawić jakość relacji międzyludzkich;</li>
          <li>przeżywającym trudności związane z rozstaniem lub rozwodem;</li>
          <li>mającym trudności w pracy, na studiach, w szkole;</li>
          <li>doświadczają lęku, niepewności, fobii;</li>
          <li>kobietom z depresją poporodową;</li>
          <li>doświadczają zaburzeń nastroju, w tym depresji;</li>
          <li>są w żałobie;</li>
          <li>zmagających się z nowotworem od momentu diagnozy oraz przez wszystkie etapy leczenia;</li>
          <li>których bliska osoba zmaga się z chorobą;</li>
          <li>pragnącym wprowadzić zmiany w dotychczasowy życiu, chcącym poznać bardziej siebie;</li>
        </ul>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>Psychoterapia zalecana jest także w przypadku:</p>
        <ul>
          <li>zaburzeń nerwicowych;</li>
          <li>myśli samobójczych;</li>
          <li>zaburzeń osobowości;</li>
          <li>zaburzeń odżywiania;</li>
          <li>braku satysfakcji w życiu seksualnym;</li>
          <li>zaburzeń adaptacyjnych;</li>
          <li>zaburzeń psychosomatycznych.</li>
        </ul>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>Psychoterapia może mieć charakter krótkoterminowy, wtedy spotkania odbywają się raz w tygodniu przez kilka
          miesięcy lub długoterminowy trwa od roku do kilku lat.
          Sesje terapeutyczne odbywają się zazwyczaj raz w tygodniu, czasami konieczne jest zwiększenie częstotliwości
          wizyt. Długość terapii zależy od problemu i wyznaczonych celów.</p>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>
