import {Component, OnInit} from '@angular/core';
import {CardDetails} from '../common/model/card-details';
import {TherapyService} from '../common/services/therapy.service';

@Component({
  selector: 'app-therapy',
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.scss']
})
export class TherapyComponent implements OnInit {

  constructor(private therapyService: TherapyService ) {
  }

  ngOnInit(): void {
  }

  getCategories(): CardDetails[] {
    return this.therapyService.getMainCategories();
  }

  getDetails(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/individual.jpeg',
      title: 'Psychoterapia indyvidualna',
      details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum urna pretium dolor bibendum tincidunt. Integer egestas ipsum eget lectus rutrum bibendum. Integer metus sem, vulputate id nulla eget, vehicula porttitor neque. Nunc at lobortis libero, accumsan porttitor dui. Aliquam ac tincidunt odio.',
      actionUrl: ''
    };
  }
}
