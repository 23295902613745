import { Component, OnInit } from '@angular/core';
import {Blockquote} from '../../common/model/blockquote';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Lepiej nauczyć dzieci właściwie postępować z własnej woli, a nie dlatego, że mama i tata patrzą im przez ramię.',
      author: 'Harlan Coben'
    };
  }
}
