<header>
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark blue ie-nav" [containerInside]="false">
    <mdb-navbar-brand>
      <a class="navbar-brand" routerLink="/main" (click)="setActive('main')">
        <img class="img-fluid nav-logo" src="../assets/img/logo.png"/>
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item waves-light" [class.active]="current=='about-me'" (click)="setActive('about-me')" mdbWavesEffect>
          <a class="nav-link" routerLink="/about-me" routerLinkActive="active">O mnie</a>
        </li>
        <li class="nav-item waves-light" [class.active]="current=='therapy'" (click)="setActive('therapy')" mdbWavesEffect>
          <a class="nav-link" routerLink="/therapy" routerLinkActive="active">Terapia</a>
        </li>
        <li class="nav-item waves-light" [class.active]="current=='article'" (click)="setActive('article')" mdbWavesEffect>
          <a class="nav-link" routerLink="/article" routerLinkActive="active">Artykuły</a>
        </li>
        <li class="nav-item waves-light" [class.active]="current=='contact'" (click)="setActive('contact')" mdbWavesEffect>
          <a class="nav-link" routerLink="/contact" routerLinkActive="active">Kontakt</a>
        </li>
      </ul>
      <ul class="navbar-nav nav-flex-icons">
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link"><mdb-icon fab icon="facebook-f"></mdb-icon></a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link"><mdb-icon fab icon="twitter"></mdb-icon></a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link"><mdb-icon fab icon="instagram"></mdb-icon></a>
        </li>
      </ul>
    </links>
  </mdb-navbar>
</header>
<!-- Main -->
<router-outlet></router-outlet>

<!-- Footer -->
<footer class="page-footer font-small blue fixed-bottom">
  <!-- Copyright -->
  <div class="footer-copyright text-center py-1">© 2020 Copyright:
    <a href="https://rozumiemysiebie.pl/"> rozumiemysiebie.pl</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
