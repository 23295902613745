import { Injectable } from '@angular/core';
import {CardDetails} from '../model/card-details';

@Injectable({
  providedIn: 'root'
})
export class TherapyService {

  constructor() { }

  getMainCategories(): CardDetails[] {
    return [
      this.getFirstStep(),
      this.getIndividual(),
      this.getSupport(),
      this.getEducation(),
      this.getCouple(),
      this.getFamily()
    ];
  }

  private getIndividual(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/individual.jpeg',
      title: 'Psychoterapia indywidualna',
      details: 'Psychoterapia jest niezwykłym spotkaniem między osobą, która potrzebuje pomocy a terapeutą, podczas którego nawiązuje się relacja terapeutyczna. Dzięki czemu możesz poczuć się bezpiecznie.',
      actionUrl: '/therapy/individual'
    };
  }

  private getFirstStep(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/first-step.jpg',
      title: 'Pierwszy krok',
      details: 'Konsultacje psychologiczne są pierwszym etapem spotkania z psychologiem. Przeważnie trwają od dwóch do trzech spotkań, aby jak najlepiej poznać Twój problem.',
      actionUrl: '/therapy/first-step'
    };
  }

  private getSupport(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/support.jpg',
      title: 'Wsparcie psychologiczne',
      details: 'Jeśli doświadczasz stresującej sytuacji życiowej, czujesz przeciążenie z powodu zbyt dużej ilości spraw, jesteś przemęczony, masz trudność w przystosowaniu się do zmian.',
      actionUrl: '/therapy/support'
    };
  }

  private getEducation(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/education.jpg',
      title: 'Konsultacje wychowawcze',
      details: 'Konsultacje wychowawcze są formą poradnictwa psychologicznego i wychowawczego a nie oddziaływaniem terapeutycznym.',
      actionUrl: '/therapy/education'
    };
  }

  private getCouple(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/couple.jpg',
      title: 'Terapia par/małżeńska',
      details: 'Dynamika relacji, związku zakłada, że pojawiają się konflikty czy kłótnie, jednak pytanie jest takie - jak z takim kryzysem sobie radzicie?.',
      actionUrl: '/therapy/couple'
    };
  }

  private getFamily(): CardDetails {
    return {
      imgUrl: '../../assets/img/therapy/family.jpeg',
      title: 'Terapia rodzinna',
      details: 'Na terapię rodzinną zapraszam całą rodzinę, ponieważ tworzą oni system, w którym członkowie żyją we wzajemnych relacjach i wzajemnie na siebie wpływają.',
      actionUrl: '/therapy/family'
    };
  }
}
