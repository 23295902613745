import {Component, Input, OnInit} from '@angular/core';
import {Blockquote} from '../../model/blockquote';

@Component({
  selector: 'app-blockquote',
  templateUrl: './blockquote.component.html',
  styleUrls: ['./blockquote.component.scss']
})
export class BlockquoteComponent implements OnInit {

  @Input() blockquote: Blockquote;

  constructor() { }

  ngOnInit(): void {
  }
}
