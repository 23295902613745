import { Component, OnInit } from '@angular/core';
import {Blockquote} from '../../common/model/blockquote';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Martwimy się, kim nasze dziecko będzie jutro, ale zapominamy, że ono jest kimś już dziś.',
      author: 'Stacia Tauscher'
    };
  }
}
