import {Component, OnInit} from '@angular/core';
import {Article} from '../common/model/article';
import {ArticleService} from '../common/services/article.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  constructor(private readonly articleService: ArticleService) {
  }

  ngOnInit(): void {
  }

  getArticles(): Article[] {
    return this.articleService.getArticles();
  }
}
