import { Component, OnInit } from '@angular/core';
import { Blockquote } from '../common/model/blockquote';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Człowiek jest jak żaglowiec, ma swój żagiel, ma ster i wszystko, co potrzebne, żeby popłynąć... Terapia to podmuch wiatru, który sprawia, że żaglowiec płynie do celu.',
      author: 'Milton H. Ericson'
    };
  }
}
