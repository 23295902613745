import { Component, OnInit } from '@angular/core';
import {Blockquote} from '../common/model/blockquote';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Dopóki nie uczynisz nieświadomego świadomym, będzie ono kierowało Twoim życiem, a Ty będziesz nazywał to przeznaczeniem.',
      author: 'Carl Gustav Jung'
    };
  }
}
