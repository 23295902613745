import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../../common/model/article';

@Component({
  selector: 'app-article-mini-card',
  templateUrl: './article-mini-card.component.html',
  styleUrls: ['./article-mini-card.component.scss']
})
export class ArticleMiniCardComponent implements OnInit {

  @Input() article: Article;

  constructor() { }

  ngOnInit(): void {
  }

}
