<div class="container">
  <div class="row">
    <div class="col-md-4">
      <a [routerLink]="article.id" mdbWavesEffect>
        <div class="article-card-picture view overlay hoverable">
          <img [src]="article.imgUrl" class="img-fluid" [alt]="article.title">
        </div>
      </a>
    </div>
    <div class="col-12 col-md-8">
      <div class="article-card-description">
        <div class="article-card-title">
          {{article.title}}
        </div>
        <div class="article-card-text">
          <p>{{article.details}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
