<div id="family">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Terapia rodzinna</div>
        <p>
          Na terapię rodzinną zapraszam całą rodzinę, ponieważ tworzą oni system, w którym członkowie żyją we wzajemnych
          relacjach i wzajemnie na siebie wpływają.
        </p>
        <app-online></app-online>
      </div>
    </div>
    <div class="row text-part">
      <p>
        Kiedy odczuwacie w rodzinie, że problemy jednego z członków rodziny wpływają na relacje z pozostałymi np. w
        przypadku problemów w szkole, choroby itd. Kiedy macie poczucie, że relacje poszczególnych członków rodziny
        nasilają problemy innego członka rodziny, kiedy trudno siebie nawzajem zrozumieć, usłyszeć, albo rodzina
        przeżywa kryzys życiowy (nagłym wypadkiem, stratą itp.) czy nie radzi sobie ze skutkami nadchodzących zmian.
        Kiedy pojawiają się w rodzinie częste konflikty. Kiedy rodziców niepokoi zachowanie rodzeństwa względem siebie
        lub niepokoi zachowanie dziecka, kiedy mają poczucie, że tracą z nim kontakt lub chcieliby aby ten kontakt był
        lepszy - konsultacja rodzinna może być bardzo dobrym pomysłem. W trakcie pierwszego lub dwóch spotkań rodzina
        razem z psychoterapeutą wspólnie podejmą decyzję co do celowości podjęcia terapii.
      </p>
      <p>
        Terapia rodzinna nie faworyzuje żadnego z członków rodziny ani nie obwinia któregokolwiek z nich za pojawiające
        się problemy. Jednym z założeń terapii jest to, że w systemie na zachowania każdej osoby mają wpływ zachowania
        innych członków rodziny. Co może być odciążające dla członka rodziny, który uważa się „za problem”. Pozwala
        dostrzec, że każdy z członków rodziny może nad sobą popracować dla ulepszenia wspólnych relacji. Co jednocześnie
        może być źródłem dużej ulgi i motywacji. Rolą terapeuty jest analizowanie różnych zachowań i ukazanie rodzinie
        jaki może być początek danych emocji czy trudności. Przygląda się nie tylko sytuacji tu i teraz, ale również
        rodzinie pochodzenia rodziców. Ukazuje jaki to może mieć wpływ na obecna sytuację. Poprawia komunikacje między
        członkami rodziny, pomaga przywrócić zagubiony wzajemny szacunek, akceptacje, spokój i miłość.
      </p>
      <p>
        Terapia rodzinna przede wszystkim daje przestrzeń na zrozumienie siebie nawzajem.
      </p>
    </div>
    <div class="row text-part">
      <p>
        Ile czasu trwa terapia rodzinna?
      </p>
      <p>
        Cykl spotkań terapeutycznych może trwać od kilku do kilkunastu np. 12 - 20 - czasem więcej spotkań sesji terapii
        rodzinnej. Czas trwania od 60 do 90 min. Najczęściej spotkania odbywają się raz na dwa tygodnie.
      </p>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>

