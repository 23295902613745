<div id="article-details">
  <div class="container">
    <div class="row article-details-delimiter">
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col article-details-header">
            {{details.article.title}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-12 col-md-8 article-details-picture">
            <img class="img-fluid" [src]="details.article.imgUrl">
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="row">
          <div class="article-details-text">
            <span [innerHTML]="details.fullText"></span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <p>Pozostałe artykuły</p>
        <app-article-mini-card *ngFor="let article of articles" [article]="article"></app-article-mini-card>
      </div>
    </div>
    <div class="row article-details-delimiter">
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>
