import { Injectable } from '@angular/core';
import {Article, ArticleDetails} from '../model/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor() { }

  getArticles(): Article[] {
    return [
      this.inspirations()
    ];
  }

  private inspirations(): Article {
    return {
      id: 'inspirations',
      imgUrl: '../../../assets/img/article/inspirations.jpg',
      title: 'Artykuły w tworzeniu',
      details: ''
    };
  }

  private senility(): Article {
    return {
      id: 'senility',
      imgUrl: '../../../assets/img/article/senility.jpg',
      title: 'Nie udała się Panu Bogu starość',
      details: 'Litwo! Ojczyzno moja! Ty jesteś jak zdrowie. Ile cię trzeba cenić, ten tylko są nasze na pole. Jutro i dziwniejsze od króla Stanisława. Ojcu Podkomorzego zdał się ta niewiastą już się na pole. Jutro i w domu i obiegłszy dziedziniec zawrócił przed laty, nad nim dla zabawki Bo nie mógł schwytać.'
    };
  }

  getArticle(identity: string): ArticleDetails {
    return this.getArticles()
      .filter(article => article.id === identity)
      .map(article => this.toDetails(article))
      .pop();
  }

  toDetails(article: Article): ArticleDetails {
    return {
      article,
      fullText: ''
    };
  }

  private getArticleExample(): Article {
    return {
      id: 'jealousy',
      imgUrl: '../../../assets/img/article/jealousy.jpg',
      title: 'Zazdrość',
      details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce fermentum urna pretium dolor bibendum tincidunt. Integer egestas ipsum eget lectus rutrum bibendum. Integer metus sem, vulputate id nulla eget, vehicula porttitor neque. Nunc at lobortis libero, accumsan porttitor dui. Aliquam ac tincidunt odio.',
    };
  }

  private getArticleText(): string {
    return `
              <p>
                Litwo! Ojczyzno moja! Ty jesteś jak zdrowie.
              </p>
              <p>
                Ile cię trzeba cenić, ten tylko są nasze na pole.
              </p>
              <p>
                Jutro i dziwniejsze od króla Stanisława. Ojcu Podkomorzego zdał się ta niewiastą już się na pole. Jutro i w domu i
                obiegłszy dziedziniec zawrócił przed laty, nad nim dla zabawki Bo nie mógł schwytać. Wojskiego też co je
                napełnił myślami. Po tem nic nie był, gdy raptem boczne drzwi poglądał jakby za dowód dobroci? Zresztą zdać
                się damom, starcom i kończył tak przekradł się o naszym wojsku wie Jegomość? Nic a pani ta tłuszcza. Bo nie
                gadał lecz zagorzalec wysadził się Hreczecha, a pani ta prędka, zmieszana rozmowa w ręku trzyma obyczajem
                pańskim i objął gospodarstwo. przyrzekł na sklepieniu. Goście weszli w Niemni i światem. Dawniej na gości
                prosi w niemieckiej karecie. Sam Podczaszyc jaki był, lecz patrzył wzrokiem śmiałym, w Litwie chodził tępy nie
                pyta bo tak na stopniach ołtarzów, Że ojciec w stolic i przepraszał Sędziego. Sędzia się sploty. Kolor musiał
                pochodzić od Moskali, skakał kryć się raczéj jako naprzykrzona mucha. Pragnąłby u jednej dwórórki. Wyczha!
                poszli, a oni tak rzadka.
              </p>
    `;
  }
}
