<div id="therapy">
  <div class="container">
    <div class="row">
      <div *ngFor="let category of getCategories()" class="col-sm-12 col-md-6 col-xl-4">
        <app-card [details]="category"></app-card>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>
