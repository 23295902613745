import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ArticleService} from '../../common/services/article.service';
import {Article, ArticleDetails} from '../../common/model/article';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent implements OnInit, OnDestroy {
  identity: string;
  details: ArticleDetails;
  articles: Article[];
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private service: ArticleService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params: Params) => {
      this.identity = params.id;
      this.details = this.service.getArticle(this.identity);
      this.articles = this.service.getArticles()
        .filter(article => article.id !== this.identity);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
