import { Component, OnInit } from '@angular/core';
import {Blockquote} from '../../common/model/blockquote';

@Component({
  selector: 'app-couple',
  templateUrl: './couple.component.html',
  styleUrls: ['./couple.component.scss']
})
export class CoupleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Niedojrzała miłość mówi: „Kocham Cię, ponieważ Cię potrzebuję. Dojrzała miłość mówi: Potrzebuję Cię, ponieważ Cię kocham.',
      author: 'Erich Fromm'
    };
  }

}
