<div id="main">
  <div id="background">
    <div class="container">
      <div class="row" >
        <div class="col-1 col-md-6"></div>
        <div class="col-10 col-md-6 main-blockquote">
          <blockquote>
            <p class="sentence">{{getBlockquote().sentence}}</p>
            <p class="author">{{getBlockquote().author}}</p>
          </blockquote>
        </div>
        <div class="col-1 col-md-3"></div>
        <div class="col 12 down-element" >
        </div>
      </div>
    </div>
  </div>
</div>
