<div id="about-me">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Małgorzata Makulińska</div>
        <span class="block-header">psycholog, psychoterapeuta, terapeuta rodzinny</span>
        <p>Przyjmuję na terapię indywidualną dorosłych i młodzież od 15 roku życia. Prowadzę również terapię systemową par, małżeństw i rodzin oraz konsultacje wychowawcze</p>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>Psychoterapia – jest drogą, która prowadzi do większego zrozumienia siebie na poziomie umysłu, duszy i ciała.
          Nasze emocje wpływają na życie codzienne, ujawniając się poprzez relacje w domu czy w pracy, jak również na
          zdrowie ciała. Mówi się, że ciało jest jak mapa – jeśli umiesz ją czytać wiele się dowiesz o osobowości i
          psychice człowieka. Emocje, pragnienia, kompleksy wyraźnie odzwierciedlają się w naszym sposobie poruszania się
          i wyglądzie. Warto pamiętać, że ciało, umysł i dusza stanowią jedność. Naruszenie jednego z tych elementów
          wpływa na pozostałe. Pracując z klientami pomagam im przezwyciężać wszelkiego rodzaju problemy. Przyglądamy się,
          co one powodują, co dają i jak wpłynęły na dotychczasowe życie klientów oraz bliskie im osoby. W wyniku naszych
          spotkań lepiej zaczynają rozumieć siebie i innych, przez co poprawiają swoje życie. Dostrzegają więcej radości,
          przyjemności i pozwalają sobie oraz bliskim na doświadczanie jej, zdobywają większe zaufanie i akceptację nie
          tylko samego siebie, ale też ważnych osób, co może wpłynąć na pogłębienie więzi. Poprawia się wzajemna
          komunikacja. Jednak przede wszystkim czują i dostrzegają więcej, co powoduje prawdziwe rozpoznawanie w sobie
          emocji. Zaczynają działać spójnie z umysłem i emocjami, z mniejszym napięciem w ciele czyli w zgodzie ze
          sobą.</p>
        <p>Również cenne dla mnie w pracy jest całościowe spojrzenie na potrzeby dziecka i jego rodziny. Może się okazać,
          że rodzina potrzebuje wsparcia terapeuty systemowego, aby zrozumieć jak zachowania dziecka odzwierciedlają to,
          co się dzieje. Problemy dziecka mogły maskować występujące w tej rodzinie trudności np. w porozumiewaniu się
          między sobą. W wyniku rozmowy z całą rodziną lub z rodzicami można wypracować zmiany, aby poprawić wzajemną
          komunikację, pogłębić bliskość i zaufanie, co wpłynie również pozytywnie na zachowanie dziecka.</p>
        <p>Mam doświadczenie w pracy m.in. z chorobami psychosomatycznymi, zaburzeniami odżywiania, depresją, lękami,
        chwiejnością nastroju, trudnościach w relacjach z ludźmi, kryzysach emocjonalnych, kryzysach w związku oraz problemach wychowawczych.</p>
        <p>Prywatnie pasjonuję się żeglarstwem i wyprawami rowerowymi oraz medytacją.</p><br/>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p class="block-header">Pracowałam m.in. w:</p>
        <ul>
          <li>Poradni Zdrowia Psychicznego w Warszawie.</li>
          <li>Specjalistycznej Poradni Profilaktyczno – Terapeutycznej dla dzieci i młodzieży OPTA, gdzie byłam psychologiem i
          terapeutą rodzinnym.</li>
          <li>Poradni Psychologiczno – Pedagogicznej w Warszawie, gdzie zajmowałam się diagnostyką
          i terapią;</li>
          <li>Fundacji i Stowarzyszeniu Pomocy Osobom Uzależnionym i Dzieciom KARAN, gdzie prowadziłam poradnictwo
          psychologiczne dla dzieci, młodzieży i ich rodziców.</li>
          <li>Mam również doświadczenie w prowadzeniu szkoleń z dorosłymi z zakresu: zaburzeń odżywiania, asertywności, radzenia
          sobie ze złością, treningu umiejętności wychowawczych itp.</li>
          <li>Prowadziłam także ćwiczenia i wykłady dla studentów pedagogiki z zakresu psychologii
          i psychoterapii.</li>
          <li>Uczestniczyłam w stażach m.in. na oddziale psychiatrycznym w Wojskowym Instytucie Medycznym, Poradni Zdrowia
          Psychicznego w Pruszkowie i Poradni Zdrowia Psychicznego dla Dzieci i Młodzieży SPZLO Praga Południe.</li>
          <li>Jestem pod stałą superwizją.</li>
          <li>Stale podnoszę swoje kwalifikacje na licznych kursach i szkoleniach.</li>
        </ul>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p class="block-header">Ukończona edukacja:</p>
        <ul>
          <li>
            <span class="education_name">Ośrodek Psychoterapii Systemowej w Krakowie</span>
            <p>Terapia Rodzin– czteroletnie studia podyplomowe (rekomendowane przez PTP).</p>
          </li>
          <li>
            <span class="education_name">Szkoła Wyższa Psychologii Społecznej w Warszawie</span>
            <p>Diagnoza psychologiczna w praktyce klinicznej. Studia podyplomowe</p>
          </li>
          <li>
            <span class="education_name">Szkoła Wyższa Psychologii Społecznej w Warszawie</span>
          <p>Psychologia w indywidualnym toku studiów. Studia magisterskie</p>
          </li>
          <li>
            <span class="education_name">Uniwersytet Warszawski</span>
          <p>Edukacja początkowa i terapia pedagogiczna. Studia podyplomowe</p>
          </li>
          <li>
            <span class="education_name">Akademia Pedagogiki Specjalnej im. M. Grzegorzewskiej w Warszawie</span>
          <p>Kierunek: Pedagogika Specjalna. Studia magisterskie</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p class="block-header">Uczestniczyłam w licznych kursach m.in:</p>
        <ul>
          <li>Podejście egzystencjalne-psychoterapia Gestalt. IPIG w Krakowie</li>
          <li>Podejście poznawczo-behawioralne. Ośrodek Psychoterapii Systemowej w Krakowie</li>
          <li>Roczne szkolenie z zakresu Psychoterapii Psychodynamicznej w Ośrodku Psychoterapii Systemowej w Krakowie.</li>
          <li>I Stopien Terapii Skoncentrowanej na Rozwiązaniach. LETSR</li>
          <li>Wstęp do pracy z dzieckiem i rodziną. LETSR</li>
          <li>Terapia Integracji Sensorycznej (I i II stopień) PSTIS</li>
          <li>Edukacja skoncentrowana na rozwiązaniu. LETSR</li>
          <li>Konferencja „Autyzm z klasą” Edukacja i integracja uczniów z autyzmem lub zespołem Aspergera w szkołach różnego typu. SYNAPSIS</li>
          <li>Zrozumieć, aby pomoc dziecku z zespołem Aspergera w domu i w szkole. SOTIS</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>
