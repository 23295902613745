import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from './main/main.component';
import {AboutMeComponent} from './about-me/about-me.component';
import {TherapyComponent} from './therapy/therapy.component';
import {ArticleComponent} from './article/article.component';
import {ContactComponent} from './contact/contact.component';
import {FamilyComponent} from './therapy/family/family.component';
import {CoupleComponent} from './therapy/couple/couple.component';
import {IndividualComponent} from './therapy/individual/individual.component';
import {ArticleDetailsComponent} from './article/article-details/article-details.component';
import {FirstStepComponent} from './therapy/first-step/first-step.component';
import {SupportComponent} from './therapy/support/support.component';
import {EducationComponent} from './therapy/education/education.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: MainComponent },
  { path: 'about-me', component: AboutMeComponent },
  { path: 'therapy', component: TherapyComponent },
  { path: 'article', component: ArticleComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'therapy/family', component: FamilyComponent },
  { path: 'therapy/couple', component: CoupleComponent },
  { path: 'therapy/individual', component: IndividualComponent },
  { path: 'therapy/first-step', component: FirstStepComponent },
  { path: 'therapy/support', component: SupportComponent },
  { path: 'therapy/education', component: EducationComponent },
  { path: 'article/:id', component: ArticleDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
