<div id="contact">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <iframe class="contact-map"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=400&amp;height=400&amp;hl=en&amp;q=Ludwika%20Wo%C5%82owskiego%205%20PRUSZK%C3%93W+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        </iframe>
      </div>
      <div class="col-12 col-lg-6">
        <div class="contact-description">
          <div class="row">
            <div class="col">
              <p class="contact-name">Centrum Rehabilitacji Ruchowej</p>
              <p class="contact-name">i Mowy RIM</p>
              <p class="contact-details">ul. Ludwika Wołowskiego 5</p>
              <p class="contact-details">05-800 Pruszków</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <p class="contact-details"><i class="fas fa-phone"></i></p>
            </div>
            <div class="col-10">
              <p class="contact-details">999-999-999</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <p class="contact-details"><i class="fas fa-envelope-square"></i></p>
            </div>
            <div class="col-10">
              <p class="contact-details">kontakt@rozumiemysiebie.pl</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
