<div id="first-step">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Pierwszy krok</div>
        <p>
          Konsultacje psychologiczne są pierwszym etapem spotkania z psychologiem. Przeważnie trwają od dwóch do trzech
          spotkań, aby jak najlepiej poznać Twój problem.
        </p>
        <app-online></app-online>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>
          Każda sesja trwa 50 minut. Po ich odbyciu psycholog informuje Cię jakie formy pomocy są dla Ciebie najlepsze,
          jakie mogą być rezultaty i szacunkowy czas trwania. Jaka forma psychoterapii będzie najbardziej odpowiednia-
          indywidualna, grupowa, małżeńska czy rodzinna. Jeśli istnieją ku temu podstawy, niektóre terapie można łączyć
          np. terapię rodzinną z psychoterapią indywidualną poszczególnego członka rodziny (prowadzone przez różnych
          terapeutów).
        </p>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>
