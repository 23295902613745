import { Component, OnInit } from '@angular/core';
import {Blockquote} from '../../common/model/blockquote';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Życia nie można wybrać, ale można z niego coś zrobić.',
      author: 'Peter Lippert'
    };
  }
}
