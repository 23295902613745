import { Component, OnInit } from '@angular/core';
import {Blockquote} from '../../common/model/blockquote';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getBlockquote(): Blockquote {
    return {
      sentence: 'Człowiek nie może odkryć nowych oceanów dopóki nie znajdzie odwagi, żeby stracić z oczu brzeg.',
      author: 'Andre Gide'
    };
  }
}
