<mdb-card>
  <div class="view rgba-white-slight waves-light card-image" mdbWavesEffect>
    <mdb-card-img [src]="details.imgUrl" alt="Card image cap"></mdb-card-img>
    <a>
      <div class="mask"></div>
    </a>
  </div>
  <mdb-card-body>
    <mdb-card-title>
      <h4>{{details.title}}</h4>
    </mdb-card-title>
    <mdb-card-text >
      <div class="card-details">
        {{details.details}}
      </div>
    </mdb-card-text>

  </mdb-card-body>
  <a mdbBtn color="primary" size="sm" [routerLink]="details.actionUrl" mdbWavesEffect>Szczegóły</a>
</mdb-card>
