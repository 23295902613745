<div id="education">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Konsultacje wychowawcze</div>
        <p>Konsultacje wychowawcze są formą poradnictwa psychologicznego i wychowawczego a nie oddziaływaniem
          terapeutycznym.</p>
        <app-online></app-online>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>
          Jest to wsparcie dla rodziców w trudnej sytuacji rodzicielskiej, pomagające wypracować wskazówki możliwych
          działań, aby rozwiązać problem. Tematem konsultacji mogą być różnego rodzaju trudności - np. niepokój związany
          z zachowaniem dziecka, rodzica, przeżywanie kryzysów rodzicielskich. Naturalna chęć znalezienia odpowiedzi na
          swoje wątpliwości, pytania, przypuszczenia oraz wsparcia w postępowaniu ze swoim dzieckiem.
        </p>
        <p>
          Najlepiej aby w konsultacji wychowawczej uczestniczyli oboje rodzice, jednak mogą odbywać się też spotkania
          indywidualne.
        </p>
        <p>
          Spotkania odbywają się raz na dwa tygodnie. Czas trwania 50-60 minut.
        </p>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>

