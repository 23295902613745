<div id="couple">
  <div class="container">
    <div class="row main-part">
      <div class="col-md-4 picture">
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <app-blockquote [blockquote]="getBlockquote()"></app-blockquote>
          </div>
        </div>
        <div class="header">Terapia par/małżeńska</div>
        <p>
          Dynamika relacji, związku zakłada, że pojawiają się konflikty czy kłótnie, jednak pytanie jest takie - jak z
          takim kryzysem sobie radzicie?
        </p>
        <app-online></app-online>
      </div>
    </div>
    <div class="row text-part">
      <p>
        Jak się zachowujecie do siebie nawzajem, czy partner zaczyna Cię lekceważyć, czy siebie ranicie czy myślicie już
        o rozstaniu?
        Czasem, aby usłyszeć siebie nawzajem, poprawić wzajemną komunikację, naprawić relacje i aby szczerze zacząć na
        nowo z sobą rozmawiać wystarczy rozmowa w obecności psychoterapeuty. Już od pierwszych spotkań ćwiczona jest
        skuteczna komunikacja zmniejszająca konflikty, odbudowywanie wzajemnego zaufania i budowanie atmosfery.
      </p>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>Jeśli przeżywacie w związku takie problemy jak:</p>
        <ul>
          <li>niezrozumienie wzajemnych potrzeb;</li>
          <li>zdrada;</li>
          <li>częste konflikty;</li>
          <li>poczucie osamotnienia;</li>
          <li>brak bliskości i intymności;</li>
          <li>niezrozumienie wzajemnych emocji i zachowań;</li>
          <li>osłabienie zainteresowania;</li>
          <li>chęć rozstania, rozwodu możliwe, że terapia par jest dla Was.</li>
        </ul>
      </div>
    </div>
    <div class="row text-part">
      <div class="col-md-12">
        <p>
          Terapeuta nie będzie oceniać ani Waszego związku ani zachowania. Jednym z moich zadań będzie tworzenie
          bezpiecznej przestrzeni, w której każdy z partnerów jest wspierany i ma możliwość zabrania głosu. Terapeuta par
          jest osobą bezstronną, czuwa nad przebiegiem rozmów i pomaga, aby rozmowy były konstruktywne. Stwarza
          przestrzeń, w której obydwoje partnerzy mogą czuć się bezpiecznie i w bezpiecznych warunkach mogą opowiadać o
          swoich trudnych odczuciach, zadaje pytania pomocnicze, wspiera. Pytam o Wasze oczekiwania związane z terapią.
          Wspólnie ustalamy cel terapii i zasady współpracy, które będą odpowiadały obydwu partnerom. Nie podaje gotowej
          recepty na rozwiązanie konfliktu, razem szukamy rozwiązania i to Wy decydujecie czy oraz kiedy wdrażacie je w
          życie. Terapia par może pomóc odzyskać zagubioną bliskość i intymność w związku.
        </p>
        <p>
          W terapii par (małżeńskiej) może uczestniczyć każda para żyjąca w formalnych i nieformalnych związkach
          heteroseksulanych i homoseksualnych. Spotkania odbywają się raz na dwa tygodnie i trwają 60 minut. Czasami
          wystarczy kilka spotkań a czasami konieczna jest dłuższa praca nad związkiem.
        </p>
      </div>
    </div>
  </div>
  <div class="bottom-part">
  </div>
</div>

