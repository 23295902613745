import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AboutMeComponent } from './about-me/about-me.component';
import { ContactComponent } from './contact/contact.component';
import { MainComponent } from './main/main.component';
import { TherapyComponent } from './therapy/therapy.component';
import { ArticleComponent } from './article/article.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CardComponent } from './common/components/card/card.component';
import { FamilyComponent } from './therapy/family/family.component';
import { CoupleComponent } from './therapy/couple/couple.component';
import { IndividualComponent } from './therapy/individual/individual.component';
import { ArticleDetailsComponent } from './article/article-details/article-details.component';
import { BlockquoteComponent } from './common/components/blockquote/blockquote.component';
import { FirstStepComponent } from './therapy/first-step/first-step.component';
import { SupportComponent } from './therapy/support/support.component';
import { EducationComponent } from './therapy/education/education.component';
import { ArticleCardComponent } from './article/article-card/article-card.component';
import { ArticleMiniCardComponent } from './article/article-mini-card/article-mini-card.component';
import { OnlineComponent } from './common/components/online/online.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    ContactComponent,
    MainComponent,
    TherapyComponent,
    ArticleComponent,
    CardComponent,
    FamilyComponent,
    CoupleComponent,
    IndividualComponent,
    ArticleDetailsComponent,
    BlockquoteComponent,
    FirstStepComponent,
    SupportComponent,
    EducationComponent,
    ArticleCardComponent,
    ArticleMiniCardComponent,
    OnlineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
